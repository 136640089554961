import {Form, Input, Modal, Tree} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {getAppDept, getDeptTree, updateAppDept} from "../../api";
import {
    showFail,
    showNeedCompleteInfo, showSuccess,
    transformToDeptTree
} from "../functions";
import {AxiosError} from "axios";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};
const EditAppDeptModal = ({
                              open,
                              onCancel,
                              record
                          }: { open: boolean, onCancel: () => void, record: any }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false)
    useResetFormOnCloseModal({
        form,
        open,
    });

    useEffect(() => {
        if (open) {
            form.resetFields();
            handleGetDeptList(record?.value)
            handleGetDeptTree()

        }
    }, [open])

    // const handleAddNewDict = () => {
    //     form.validateFields().then((value) => {
    //         setLoading(true);
    //         updateDict({...value, id: record.id, isLook: 1}).then((res: any) => {
    //             setLoading(false);
    //             if (res.code === 200) {
    //                 handleGetDict().then(() => {
    //                     showSuccess(res?.msg);
    //                 });
    //                 onCancel();
    //             } else {
    //                 showFail(res?.msg)
    //             }
    //         }).catch((err: any) => {
    //             console.log(err)
    //         })
    //     }).catch((err) => {
    //         console.log(err);
    //         showNeedCompleteInfo();
    //     })
    // }

    const [selectedKeys, setSelectedKeys] = useState<any[]>([]);

    const handleGetDeptList = async (appId: string) => {
        getAppDept({appId}).then((res: any) => {
            setSelectedKeys(res?.data?.map((d: any) => d?.deptId));
        })
    }

    const [deptTree, setDeptTree] = useState<any>([]);
    const handleGetDeptTree = async () => {
        let params = {}
        getDeptTree(params).then((res: any) => {
            let dept = transformToDeptTree([res?.data]);
            setDeptTree(dept);
        }, (err: AxiosError) => {
            console.log(err);
        });
    }

    const onCheck = (selectedKeys: any) => {
        setSelectedKeys(selectedKeys?.checked);
    }


    const handleSubmitData = () => {
        console.log('selectedKeys', selectedKeys);

        let data={
            deptIdList:selectedKeys,
            appId:record?.value
        }
        console.log('data',data)

        // setLoading(true);
        updateAppDept(data).then((res: any) => {
            setLoading(false);
            if (res.code === 200) {
                showSuccess("更新成功",()=>{
                    onCancel();
                })
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    return (
        <div>
            <Modal width={800} open={open} onCancel={onCancel} title={'新增配置'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleSubmitData, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="description"
                        label="类型"
                        initialValue={record?.description}
                    >
                        <Input placeholder="请输入类型" disabled/>
                    </Form.Item>

                    <Form.Item
                        name="deptIdList"
                        label="生效部门"
                    >
                        <Tree
                            checkable
                            checkedKeys={selectedKeys}
                            onCheck={onCheck}
                            treeData={deptTree}
                            checkStrictly
                        />
                    </Form.Item>
                </Form>

                <div style={{height: 50}}/>
            </Modal>
        </div>
    );
};

export default EditAppDeptModal;
import React, {useEffect, useState} from 'react';
import {abolishAppointment, retreatToLeadTeam} from "../../api";
import {showFail, showNeedCompleteInfo, showNetError, showSuccess, showWaringOfCustomDuration} from "../functions";
import {Button, DatePicker, Form, Input, Modal} from 'antd';
//@ts-ignore
import {InfoWindow, Map, Marker, Markers} from "react-amap";
import AddNewPoiModal from "../addNewPoiModal";
import EditPoiModal from "../editPoiModal";

const AppointmentAbolishModal = ({
                                     open,
                                     onCancel,
                                     appointmentRecord,
                                     handleGetAppointmentRecord
                                 }: {
    open: boolean,
    onCancel: () => void,
    appointmentRecord: any,
    handleGetAppointmentRecord: any
}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [open]);


    const handleAbolish = () => {
        form.validateFields().then((value: any) => {
            let data = {
                ...value,
                appointmentId: appointmentRecord?.appointmentId,
            };

            abolishAppointment(data).then((res: any) => {
                if (res?.code === 200) {
                    showSuccess("作废成功");
                    handleGetAppointmentRecord().then(() => {
                        onCancel();
                    })
                }
            }).catch((err: any) => {
                console.log(err);
                showFail(err);
            })

        }).catch((err: any) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <Modal open={open} onCancel={onCancel} title={'工单作废'} width={'800px'}
               onOk={handleAbolish}
               okText={"确认作废"}
        >
            <Form form={form} name="form" labelAlign="right" labelCol={{
                xs: {span: 24},
                sm: {span: 6},
            }} wrapperCol={{
                xs: {span: 24},
                sm: {span: 14},
            }}>

                <Form.Item
                    name="backReason"
                    label="作废原因"
                    rules={[
                        {
                            required: true, message: "请输入作废原因！"
                        },
                    ]}
                >
                    <Input.TextArea rows={5} placeholder={'请输入作废原因'}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default AppointmentAbolishModal;
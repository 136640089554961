import {message} from "antd";
import {GlobalOutlined, FormOutlined} from "@ant-design/icons"
import axios from "axios";

export const showWarning = (content: string, afterClose?: () => void) => {
    message.warning({
        content,
        duration: 1.5,
        onClose: afterClose
    })
}

export const showWaringOfCustomDuration = (content: string,duration:number, afterClose?: () => void) => {
    message.warning({
        content,
        duration: duration,
        onClose: afterClose
    })
}

export const showSuccess = (content: string, afterClose?: () => void) => {
    message.success({
        content,
        duration: 1.5,
        onClose: afterClose
    })
}


export const showFail = (content: string, afterClose?: () => void) => {
    message.error({
        content,
        duration: 1.5,
        onClose: afterClose
    })
}

export const showNetError = () => {
    message.error({
        content: 'Net Error',
        duration: 1.5,
        icon: <GlobalOutlined/>
    })
}

export const showNeedCompleteInfo = () => {
    message.error({
        content: '请完成所有必填/必选项',
        duration: 1.5,
        icon: <FormOutlined/>
    })
}


interface Node {
    value?: string;
    label?: string;
    areaId: string;
    name: string;
    delFlag: number;
    children?: Node[];
}

export function transform(node: Node): Node | null {

    if (node.delFlag === 1) {
        return null;
    }

    const result: any = {
        value: node.areaId,
        label: node.name
    };

    if (Array.isArray(node.children)) {
        result.children = [];
        for (const child of node.children) {
            const transformedChild = transform(child);
            if (transformedChild) {
                result.children.push(transformedChild);
            }
        }
    }

    return result;

}

interface Area {
    areaId: string;
    name: string;
    parentId: string;
    children?: Area[];
}

export function getAreaPath(data: Area, areaId: string): string {
    if (data.areaId === areaId) {
        return data.name;
    }

    if (data.children) {
        for (let child of data.children) {
            const path = getAreaPath(child, areaId);
            if (path) {
                return data.name + '/' + path;
            }
        }
    }

    return '';
}

export function getAreaIdArray(data: Node, areaId: string): string[] {
    let areaArray:string[]=[];
    if (data.value === areaId) {
        areaArray.push(data.value)
        return areaArray;
    }

    if (data.children) {
        for (let child of data.children) {
            const _areaArray = getAreaIdArray(child, areaId);
            if (_areaArray?.length>0) {
                _areaArray.push(data.value as string);
                return _areaArray;
            }
        }
    }

    return [];
}

export function diffArray(arr1: string[], arr2: string[]) {
    return arr1.filter(item => !arr2.includes(item));
}


export function formatDate(date: any): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，所以需要加 1
    const day = date.getDate().toString().padStart(2, '0'); // 确保日是两位数

    return `${year}-${month}-${day} 00:00:00`;
}


interface deptTreeNode {
    value?: string;
    deptId?: string;
    name?: string;
    children?: deptTreeNode[];
    label?:string,
    title?:string,
    key?:string
}

export function transformTitleToLabel(data: deptTreeNode[]): deptTreeNode[] {
    console.log('data',data)
    return data?.map((node) => {
        const newNode: deptTreeNode = {
            ...node
        };
        if (newNode.name) {
            newNode.label = newNode.name;
            delete newNode.name;
        }
        if (newNode.deptId) {
            newNode.value = newNode.deptId;
            delete newNode.deptId;
        }
        if (newNode.children && newNode.children.length > 0) {
            newNode.children = transformTitleToLabel(newNode.children);
        }
        return newNode;
    });
}

export function transformToDeptTree(data: deptTreeNode[]): deptTreeNode[] {
    return data?.map((node) => {
        const newNode: deptTreeNode = {
            ...node
        };
        if (newNode.name) {
            newNode.title = newNode.name;
            delete newNode.name;
        }
        if (newNode.deptId) {
            newNode.key = newNode.deptId;
            delete newNode.deptId;
        }
        if (newNode.children && newNode.children.length > 0) {
            newNode.children = transformToDeptTree(newNode.children);
        }
        return newNode;
    });
}


export function transformToMenuTree(data: any[]): any[] {
    return data?.map((node) => {
        const newNode: any = {
            ...node
        };
        if (newNode.name) {
            newNode.title = newNode.name;
            delete newNode.name;
        }
        if (newNode.id) {
            newNode.value = newNode.id;
            delete newNode.id;
        }
        if (newNode.children && newNode.children.length > 0) {
            newNode.children = transformToMenuTree(newNode.children);
        }
        return newNode;
    });
}

interface Role {
    title: string;
    value: string | number;
    children?: Role[];
}
export function addDisabledToMissingRoles(allSysRole: Role[], selectSysRoles: Role[]): Role[] {
    const allRoleMap = new Map<string, Role>();
    const selectRoleMap = new Map<string, Role>();

    // 将allSysRole的角色信息存入Map
    allSysRole.forEach(role => {
        allRoleMap.set(String(role.value), role);
        if (role.children) {
            role.children.forEach(child => {
                allRoleMap.set(String(child.value), child);
            });
        }
    });

    // 将selectSysRoles的角色信息存入Map
    selectSysRoles.forEach(role => {
        selectRoleMap.set(String(role.value), role);
        if (role.children) {
            role.children.forEach(child => {
                selectRoleMap.set(String(child.value), child);
            });
        }
    });

    // 遍历allRoleMap，检查在selectRoleMap中不存在的角色，并添加disabled属性
    allRoleMap.forEach((role, key) => {
        if (!selectRoleMap.has(key)) {
            (role as any).disabled = true;
        }
    });

    return allSysRole;
}




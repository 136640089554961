import {Button, Input, Popconfirm, Select, Table, Tag, TreeSelect} from 'antd';
import React, {useEffect, useState} from 'react';
import {
    getCompanyExamStatics,
    getCompanyExamTotalStatics,
} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import styles from "./index.module.css"
import {CompanyExamDetailModal} from "../../../components/companyExamDetailModal";


const ExamStatics = () => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNum, setPageNum] = useState<number>(1);
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false);
    const [record, setRecord] = useState<any>();
    const [companyId, setCompanyId] = useState<string | number>("");
    const [exerciseData, setExerciseData] = useState<any[]>([]);
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({});
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetExamStatics = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.companyName) {
            params.companyName = searchCondition.companyName;
        }
        getCompanyExamStatics(params).then((res: any) => {
            if (res.code === 200) {
                setLoading(false);
                setTotal(Number(res?.data?.total));
                setExerciseData(res.data?.records)
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }


    const [examTotal,setExamTotal]=useState<number>(0);
    const [passTotal,setPassTotal]=useState<number>(0)
    const handleGetTotalExamStatics = async () => {
        getCompanyExamTotalStatics().then((res: any) => {
            if (res.code === 200) {
                setExamTotal(res?.data?.totalCount)
                setPassTotal(res?.data?.passedCount)
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }


    useEffect(() => {
        handleGetExamStatics(pageNum, pageSize, newSearchCondition);
        handleGetTotalExamStatics()
    }, [pageSize, pageNum])


    const columns = [
        {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: '考试总人数',
            dataIndex: ["examResult", "totalCount"],
        },
        {
            title: '通过人数',
            dataIndex: ["examResult", "passedCount"],
        },
        {
            title: '详情',
            dataIndex: 'companyId',
            key: 'companyId',
            render: ((companyId: any, record: any) => (
                <>
                    <Button type={'primary'} onClick={() => {
                        setExamDetailModalOpen(true);
                        setCompanyId(companyId);
                        setRecord(record)
                    }}>查看详情</Button>
                </>

            ))
        },
    ]


    const [examDetailModalOpen, setExamDetailModalOpen] = useState<boolean>(false);
    const handleExamDetailModalClose = () => {
        setExamDetailModalOpen(false);
    }

    const handleCompanyNameChange = (e: any) => {
        setSearchCondition({...searchCondition, companyName: e.target.value})
    }

    return (
        <div>
            <div className={styles.staticsArea}>
                <div className={styles.staticsCard}>
                    <div className={styles.staticsCardTitle} style={{color:"#1245de"}}>
                        考试总人数
                    </div>
                    <div className={styles.staticsCardText}  style={{color:"#1245de"}}>
                        {examTotal}
                    </div>
                </div>
                <div className={styles.staticsCard}>
                    <div className={styles.staticsCardTitle} style={{color:"#219105"}}>
                        通过总人数
                    </div>
                    <div className={styles.staticsCardText}  style={{color:"#219105"}}>
                        {passTotal}
                    </div>

                </div>
            </div>


            <div className={styles.mainHead}>
                <div className={styles.searchContent}>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleCompanyNameChange} placeholder={'公司名称'} allowClear/>


                </div>
                <div className={styles.searchButton}>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetExamStatics(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                </div>

            </div>

            <Table rowKey={'companyId'} columns={columns} dataSource={exerciseData}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>
            <CompanyExamDetailModal open={examDetailModalOpen} onCancel={handleExamDetailModalClose}
                                    companyId={companyId as string} record={record}/>
        </div>
    );
};

export default ExamStatics;
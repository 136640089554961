import React, {useEffect, useState} from 'react';
import {CommunityPoint, iCommunity} from "../../interface";
import {
    deleteCommunityPoint,
    getCommunity,
    getDeptTree,
    getUserByRole,
    reassignHiddenDangerBackUser,
    reDispatchDept
} from "../../api";
import {showFail, showNeedCompleteInfo, showNetError, showSuccess, showWaringOfCustomDuration} from "../functions";
import {Button, DatePicker, Form, Modal, Popconfirm, Select, Table} from 'antd';
//@ts-ignore
import {InfoWindow, Map, Marker, Markers} from "react-amap";
import AddNewPoiModal from "../addNewPoiModal";
import EditPoiModal from "../editPoiModal";

const ReAssignHiddenDangerReviewerModal = ({
                               open,
                               onCancel,
                               hiddenDangerRecord,
                               handleGetHiddenDangerRecord
                           }: {
    open: boolean,
    onCancel: () => void,
    hiddenDangerRecord: any,
    handleGetHiddenDangerRecord: any
}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        handleGetReceiver();
    }, [open]);

    const [receiverList, setReceiverList] = useState<any[]>([]);
    const handleGetReceiver = () => {
        getUserByRole({roleType:4}).then((res: any) => {
            // console.log('res',res)
            let _data = res?.data?.map((d: any) => {
                return {
                    value: d?.userId,
                    label: `${d?.username} ${d?.mobile}`
                }
            });
            setReceiverList(_data);
        })
    }

    const handleReAssignReviewer = () => {
        form.validateFields().then((value: any) => {
            let newBackUserId = value?.newBackUserId;
            console.log('hiddenDangerRecord',hiddenDangerRecord)
            if (newBackUserId === hiddenDangerRecord?.backUser?.userId) {
                showWaringOfCustomDuration("不能选择原接收人", 3)
            } else {
                let data = {
                    backId: newBackUserId,
                    dangerId: hiddenDangerRecord?.dangerId,
                };

                // console.log('data',data)

                reassignHiddenDangerBackUser(data).then((res: any) => {
                    if (res?.code === 200) {
                        showSuccess("转派成功");
                        handleGetHiddenDangerRecord().then(()=>{
                            onCancel();
                        })
                    }else{
                        showFail(res?.msg)
                    }
                }).catch((err: any) => {
                    console.log(err);
                    showFail(err);
                })
            }

        }).catch((err: any) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <Modal open={open} onCancel={onCancel} title={'接收参谋转派'} width={'800px'}
               onOk={handleReAssignReviewer}
               okText={"确认转派"}
        >
            <Form form={form} name="form" labelAlign="right" labelCol={{
                xs: {span: 24},
                sm: {span: 6},
            }} wrapperCol={{
                xs: {span: 24},
                sm: {span: 14},
            }}>

                <Form.Item
                    name="oriBackUser"
                    label="原接受人"
                >
                    <div>
                        {hiddenDangerRecord?.backUser?.username}
                    </div>
                </Form.Item>

                <Form.Item
                    name="newBackUserId"
                    label="新接收人"
                    rules={[
                        {
                            required: true, message: "请选择新接收人"
                        },
                    ]}
                >
                    <Select options={receiverList}/>
                </Form.Item>
            </Form>

        </Modal>
    );
};

export default ReAssignHiddenDangerReviewerModal;
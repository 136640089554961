import React, {useEffect, useState} from 'react';
import {Button, Divider, Input, message, Modal, Popconfirm, Select, Space, Table, TreeSelect} from "antd";
import {AxiosError} from "axios";
import {PlusOutlined, TeamOutlined} from "@ant-design/icons";
import {addMenu, getMenuTree, deleteMenu, updateMenu} from "../../../api";

//菜单管理
const MenuManagement = () => {


    interface menutree {
        value?: string,
        title?: string,
        children?: any[],
    }

    //保存数据
    const [menuTree, setMenuTree] = useState([]);
    const [menus, setMenus] = useState<any>([]);

    function tree(list: menutree[], res: any[]) {
        for (let i = 0; i < res.length; i++) {
            if (res[i].children) {
                // @ts-ignore
                list.push({value: res[i].id, title: res[i].name, children: []})
            } else {
                // @ts-ignore
                list.push({value: res[i].id, title: res[i].name})
            }
            // @ts-ignore
            if (res[i].children) {
                // @ts-ignore
                tree(list[i].children, res[i].children)
            }
            // for (let j = 0; j < res[i].children.length; j++) {
            //     // @ts-ignore
            //     tree(list[i].children,res[i].children[j])
            // }
        }
        return list
        //  if(res.children && res.parentId==0) {
        //      list.push({value: res.id, title: res.name, children: []})
        //  }
        //  else if(res.children && res.parentId!=0){
        //
        //  }else{
        //      list.push({value: res.id, title: res.name})
        //  }
        // if(res.children){
        //     for (let i = 0; i < res.children.length; i++) {
        //         // @ts-ignore
        //        tree(list.children,res.children[i])
        //     }
        // }
        // return list
    }

    //查询权限Tree
    useEffect(() => {
        let params = {}
        getMenuTree(params).then((res: any) => {
            setMenuTree(res.data);
            let a: menutree[] = []
            let b = []
            b.push(res.data)
            let list = tree(a, b)
            setMenus(list)
            console.log(list)
        }, (err: AxiosError) => {
            console.log(err);
        });


    }, [])

    const onGetMenu = () => {
        let params = {}
        getMenuTree(params).then((res: any) => {
            setMenuTree(res.data);
            console.log(res.data)
        }, (err: AxiosError) => {
            console.log(err);
        });
        getMenuTree(params).then((res: any) => {
            setMenuTree(res.data);
            let a: menutree[] = []
            let b = []
            b.push(res.data)
            let list = tree(a, b)
            setMenus(list)
            console.log(list)
        }, (err: AxiosError) => {
            console.log(err);
        });
    }


    //新增对话框
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const handleAddCancel = () => {
        setIsAddModalOpen(false);
    };
    const [currentData, setCurrentData] = useState<any>('');
    //新增权限
    const AddModal = ({open, onCancel, record}: { open: any, onCancel: any, record: any }) => {
        const [addName, setAddName] = useState<string>('');
        const [addUrl, setAddUrl] = useState<string>('');
        const [addPerms, setAddPerms] = useState<string>('');
        const [addType, setAddType] = useState<string>('');
        const [remark, setRemark] = useState<string>('');
        const onAddMenu = () => {
            let a = true
            if (!addName) {
                message.error("权限名称不能为空")
                a = false
            }
            if (!parentId) {
                message.error("父权限名称不能为空")
                a = false
            }
            if (a) {
                let params = {
                    name: addName,
                    parentId: parentId,
                    url: addUrl,
                    perms: addPerms,
                    type: addType,
                    remark: remark
                };

                console.log(params)
                addMenu(params).then((res: any) => {
                    if (/^5[0-9][0-9]/.test(res.code)) {
                        message.error(res.msg);
                    } else {
                        message.success(res.msg);
                        setIsAddModalOpen(false);
                        onGetMenu();
                        // window.location.reload()
                    }
                }, (err: AxiosError) => {
                    console.log(err);
                })
            }
        };
        const [parentId, setParentId] = useState();
        const handleChange = (e: any) => {
            setParentId(e)
            console.log(e)
        }
        return (
            <Modal
                title='新增权限'
                open={open}
                onCancel={onCancel}
                onOk={onAddMenu}
            >

                <div style={{padding: '20px'}}>
                    <div>权限名：<Input style={{width: '300px', marginBottom: '10px'}}
                                    onChange={(e) => setAddName(e.target.value)}/></div>
                    {/*<div>备注：<Input style={{width: '300px', marginBottom: '10px'}} onChange={(e)=>setRemark(e.target.value)} /></div>*/}
                    <div>
                        <TreeSelect
                            showSearch
                            style={{width: '100%'}}
                            // value={menuTree}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            placeholder="父权限"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(e) => handleChange(e)}
                            treeData={menus}
                        />
                    </div>
                </div>
            </Modal>
        )

    }


    //删除权限
    const onDeleteMenu = (val: any) => {
        let params = {
            id: val.id,
        }
        console.log(params)

        deleteMenu(params).then((res: any) => {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            } else {
                message.success(res.msg);
                onGetMenu();
            }
        }, (err: AxiosError) => {
            console.log(err);
        })
    }

    //修改对话框
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const handleUpdateCancel = () => {
        setIsUpdateModalOpen(false);
    };
    //修改权限
    const UpdateModal = ({open, onCancel, record}: { open: any, onCancel: any, record: any }) => {
        const [updateName, setUpdateName] = useState<string>('');
        const [updateUrl, setUpdateUrl] = useState<string>('');
        const [updatePerms, setUpdatePerms] = useState<string>('');
        const [updateType, setUpdateType] = useState<string>('');
        const [pId, setPId] = useState()
        const onUpdateMenu = () => {
            let params = {
                name: updateName,
                parentId: pId,
                url: updateUrl,
                perms: updatePerms,
                type: updateType,
                id: record.id,
                remark: remark
            };

            console.log(params)
            updateMenu(params).then((res: any) => {
                if (/^4[0-9][0-9]/.test(res.code)) {
                    message.error(res.msg);
                } else {
                    message.success(res.msg);
                    console.log(res)
                    setIsUpdateModalOpen(false);
                    onGetMenu();
                }
            }, (err: AxiosError) => {
                console.log(err);
            })
        };
        const handleChange2 = (e: any) => {
            setPId(e)
        }
        const [remark, setRemark] = useState<string>('');
        return (
            <Modal
                title='新增权限'
                open={open}
                onCancel={onCancel}
                onOk={onUpdateMenu}
            >
                <div style={{padding: '20px'}}>
                    <div>权限名：<Input defaultValue={currentData.name} style={{width: '300px', marginBottom: '10px'}}
                                    onChange={(e) => setUpdateName(e.target.value)}/></div>
                    {/*<div>备注：<Input style={{width: '300px', marginBottom: '10px'}} onChange={(e)=>setRemark(e.target.value)} /></div>*/}
                    <TreeSelect
                        showSearch
                        style={{width: '100%'}}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        placeholder="父权限"
                        allowClear
                        treeDefaultExpandAll
                        onChange={(e) => handleChange2(e)}
                        treeData={menus}
                    />
                </div>
            </Modal>
        )

    }

    //表头数据
    // @ts-ignore
    const Columns = [
        {
            title: '权限名称',
            dataIndex: 'name',
            width: 200
        },
        // {
        //     title: '备注',
        //     dataIndex: 'remark',
        //     width: 200
        // },

        {
            title: '操作',
            dataIndex: 'operate',
            width: 300,
            render: (_: any, record: any, index: any) => (

                <Space>
                    <Popconfirm title="确认删除吗?" onConfirm={() => onDeleteMenu(record)}>
                        <a>删除</a>
                    </Popconfirm>
                    {record.parentId != 0 ?
                        <a onClick={() => {
                            console.log(record)
                            setCurrentData(record);
                            setIsUpdateModalOpen(true);
                        }}>修改</a> : ''}

                    {/*<a onClick={()=> {*/}
                    {/*    setCurrentData(record);*/}
                    {/*    setIsAddModalOpen(true);*/}
                    {/*}}>新增</a>*/}

                </Space>
            )
        },

    ]

    return (
        <div className='user_management_layout'>
            <div className='user_management_header' style={{marginBottom: '12px'}}><TeamOutlined/> 权限管理</div>
            <Divider style={{margin: '0'}}/>
            <div className='user_management_add'>
                <Button onClick={() => {
                    onGetMenu();
                    setIsAddModalOpen(true)
                }} type="primary"><PlusOutlined/>新增</Button>
            </div>
            <Table
                rowKey="id"
                columns={Columns}
                //rowSelection={{ ...rowSelection, checkStrictly }}
                dataSource={[menuTree]}
            />

            <AddModal record={currentData} onCancel={handleAddCancel} open={isAddModalOpen}/>

            <UpdateModal record={currentData} onCancel={handleUpdateCancel} open={isUpdateModalOpen}/>

        </div>
    );
};

export default MenuManagement;
import {Button, Collapse, Image, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {getCompanyUserExamResult} from "../../api";
import {showFail, showNetError} from "../functions";
import passIcon from "../../assets/icons/pass.svg"
import notPassIcon from "../../assets/icons/not_pass.svg"

export const CompanyExamDetailModal = ({
                                           open,
                                           onCancel,
                                           companyId,
                                           record
                                       }: { open: boolean, onCancel: () => void, companyId: string, record: any }) => {
    const [userExamData, setUserExamData] = useState<any[]>([])
    const handleGetCompanyExamDetail = () => {
        if (companyId) {
            getCompanyUserExamResult({companyId}).then((res: any) => {
                if (res?.code === 200) {
                    let scores = res?.data?.map((d: any) => {
                        let isPass: boolean = false;
                        let isPassNum = d?.sysUserExams?.filter((z: any) => (z.isPass))?.length;
                        if (isPassNum > 0) {
                            isPass = true
                        }
                        const maxScore = d?.sysUserExams?.reduce((max: any, current: any) => {
                            return current.scoreTotal > max ? current.scoreTotal : max;
                        }, -Infinity);

                        return {
                            ...d,
                            isPass,
                            maxScore
                        }
                    });
                    setUserExamData(scores);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                showNetError();
                console.log(err)
            })
        } else {
            showFail("单位id为空!")
        }

    }

    useEffect(() => {
        if (open) {
            handleGetCompanyExamDetail()
        }
    }, [open])


    console.log('userExamData', userExamData)
    return (
        <>
            <Modal open={open} onCancel={onCancel} title={`${record?.companyName}考试详情`} maskClosable={false}>

                <Collapse items={userExamData?.map((d: any) => {
                    return {
                        key: d?.userId,
                        label: (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                            {d?.isPass ? (
                                    <img src={passIcon} alt="" style={{width: 20, height: 20, marginRight: 10}}/>) :
                                (<img src={notPassIcon} alt="" style={{width: 20, height: 20, marginRight: 10}}/>)}

                            <div style={{marginRight: 10}}>
                                {d?.username}
                            </div>
                            <div>
                                {d?.maxScore}分
                            </div>
                        </div>),
                        children:d?.sysUserExams?.map((e:any)=>(
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                考试时间:{e?.examTime}, 成绩: <p style={{color:e?.scoreTotal>60?'green':'red'}}>{e?.scoreTotal}</p>分
                            </div>
                        ))
                    }
                })}/>

            </Modal>
        </>
    )
}
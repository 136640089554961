import React, {useEffect, useState} from 'react';
import {iHydrant} from "../../../interface";
import {deleteCommunity, deleteDict, getAppDept, getDictPageData, getHydrantPage} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import {Button, Cascader, Input, Popconfirm, Table} from "antd";
import styles from "../communityComponent/index.module.css";
import AddDictModal from "../../../components/addDictModal";
import EditDictModal from "../../../components/editDictModal";
import EditAppDeptModal from "../../../components/editAppDeptModal";

const App = () => {
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [dictList, setDictList] = useState<iHydrant[]>([])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({
        model: "应用",
        attribute: ""
    });
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetDictList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        params.isLook = 1;
        if (searchCondition?.model) {
            params.model = searchCondition?.model
        }
        getDictPageData(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setDictList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetDictList(pageNum, pageSize, newSearchCondition);
    }, [pageSize, pageNum])


    const columns = [
        {
            title: '应用名称',
            dataIndex: 'description'
        },
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: any) => (
                <div>
                    {isSuperManager && (
                        <Button type={'link'} onClick={() => {
                            setDictRecord(record);
                            setEditAppDeptModalVisible(true);
                        }}>
                            修改生效部门
                        </Button>
                    )}
                </div>
            )
        }
    ];

    const handleAttributeChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            attribute: e.target.value
        })
    }

    const [dictRecord, setDictRecord] = useState<any>();
    const [editAppDeptModalVisible, setEditAppDeptModalVisible] = useState<boolean>(false);
    const handleCloseEditAppDeptModal = () => {
        setEditAppDeptModalVisible(false);
    }

    return (
        <div>

            <div className={styles.mainHead}>
                <div className={styles.searchContent}>

                    <Input onChange={handleAttributeChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}
                           placeholder="名称" allowClear/>


                </div>
                <div className={styles.searchButton}>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetDictList(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                    {/*    setAddModalVisible(true)*/}
                    {/*}}>新增*/}
                    {/*</Button>*/}

                </div>
            </div>


            <Table rowKey={'id'} columns={columns} dataSource={dictList}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <EditAppDeptModal open={editAppDeptModalVisible} onCancel={handleCloseEditAppDeptModal}
                              record={dictRecord}/>

        </div>
    );
};

export default App;